<div class="min-h-0 d-flex flex-column h-100">
  <ag-grid-angular
    [context]="context"
    #agGrid
    [sideBar]="sideBar"
    class="ag-theme-alpine ld-manager-grid app-l-grid-widget"
    [defaultColDef]="defaultColDef"
    [pagination]="pagination"
    [paginationPageSize]="paginationPageSize"
    [debug]="false"
    (gridReady)="onGridReady($event)"
    [frameworkComponents]="frameworkComponents"
    [getRowStyle]="getRowStyle"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
    [overlayLoadingTemplate]="overlayLoadingTemplate"
    [autoGroupColumnDef]="autoGroupColumnDef"
    rowSelection="multiple"
    [groupSelectsChildren]="!treeData ? true : false"
    suppressRowClickSelection="true"
    [treeData]="treeData"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [getDataPath]="getDataPath"
    suppressDragLeaveHidesColumns="true"
    (filterChanged)="onFilterChanged($event)"
    (rowSelected)="onRowSelected($event)"
    (sortChanged)="onSortChanged()"
    (columnRowGroupChanged)="onColumnRowGroupChanged()"
    (columnVisible)="onColumnVisible()"
    (columnMoved)="onColumnMoved()"
    (columnEverythingChanged)="onColumnEverythingChanged()"
    (cellEditingStopped)="onCellEditingStopped($event)"
    (cellEditingStarted)="onCellEditingStarted($event)"
    [getRowClass]="getRowClass"
    [editType]="editType"
    (modelUpdated)="onModelUpdated($event)"
    [suppressContextMenu]="suppressRightClickPrivilege"
    [masterDetail]="masterDetail"
    [detailCellRendererParams]="detailCellRendererParams"
    (firstDataRendered)="onFirstDataRendered($event)"
    (rowEditingStopped)="onRowEditingStopped($event)"
    (rowEditingStarted)="onRowEditingStarted($event)"
    [isRowSelectable]="isRowSelectable"
  >
  </ag-grid-angular>
  <ag-grid-angular
    #gridHidden
    style="min-height: 500px; display: none"
    class="ag-theme-alpine"
    [rowData]="rowData"
  >
  </ag-grid-angular>
</div>
